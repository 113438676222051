<template>
    <div>
        <b-overlay
            :opacity="1"
            variant="transparent"
            blur="1rem"
            :show="modalFinalizarAsistencia"
            no-center
        >
            <b-row class="py-2">
                <b-col cols="8" offset="2">
                    <!-- card para terminar la asistencia -->
                    <b-card v-if="seguimientoEstado == 1">
                        <b-card-title>Finalizar asistencia</b-card-title>
                        <b-card-sub-title
                            >Presiona el botón si deseas finalizar la
                            asistencia</b-card-sub-title
                        >
                        <b-row>
                            <b-col class="text-center my-2">
                                <b-button
                                    variant="danger"
                                    pill
                                    class="px-75 animate__animated animate__wobble"
                                    @click="modalFinalizarAsistencia = true"
                                >
                                    <feather-icon
                                        icon="PowerIcon"
                                        size="50"
                                    ></feather-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                    <!-- card para aistencia ya terminada -->
                    <b-card v-if="seguimientoEstado == 2" class="informe">
                        <!-- ganchito del clipboard -->
                        <div
                            class="gancho pt-4 animate__animated animate__flipInX animate__delay-2s"
                        ></div>

                        <!-- hoja del clipboard -->

                        <b-card-body
                            class="papel rounded-sm animate__animated animate__fadeInUpBig"
                        >
                            <b-row>
                                <b-col class="text-center">
                                    <h3>
                                        Información de la asistencia realizada
                                    </h3>
                                    <div
                                        class="d-flex flex-column align-items-start"
                                    >
                                        <div class="mt-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Fecha:
                                            </span>
                                            <span>2 ago. 2022 10:00 am</span>
                                        </div>
                                        <div class="mt-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Hora inicio:
                                            </span>
                                            <span>10:02 am</span>
                                        </div>
                                        <div class="mt-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Hora fin:
                                            </span>
                                            <span>11:05 am</span>
                                        </div>
                                        <div class="mt-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Duración:
                                            </span>
                                            <span>1h 3m</span>
                                        </div>
                                        <div class="mt-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Paciente:
                                            </span>
                                            <span
                                                >Gian Carlos Galán Sánchez</span
                                            >
                                        </div>
                                        <div class="mt-1 pb-1">
                                            <span
                                                class="font-weight-bold font-small-4 mr"
                                                >Profesional:
                                            </span>
                                            <span>Oscar Acelas</span>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <h3 class="text-center pb-1">
                                        Equipos usados dentro de la asistencia
                                    </h3>
                                    <b-row>
                                        <b-col>Dispositivo</b-col>
                                        <b-col>Estado</b-col>
                                        <b-col>Observación</b-col>
                                    </b-row>
                                    <b-row class="py-1">
                                        <b-col class="mr-1">
                                            Blood pressure
                                        </b-col>
                                        <b-col>
                                            <b-badge variant="success" pill>
                                                <feather-icon
                                                    icon="CheckIcon"
                                                ></feather-icon>
                                            </b-badge>
                                        </b-col>
                                        <b-col>
                                            <small>Mediciones normales</small>
                                        </b-col>
                                    </b-row>
                                    <b-row class="py-1">
                                        <b-col class="mr-1">
                                            Body composition
                                        </b-col>
                                        <b-col>
                                            <b-badge variant="warning" pill>
                                                <feather-icon
                                                    icon="InfoIcon"
                                                ></feather-icon>
                                            </b-badge>
                                        </b-col>
                                        <b-col>
                                            <small>Mediciones anormales</small>
                                        </b-col>
                                    </b-row>
                                    <b-row class="py-1">
                                        <b-col class="mr-1">
                                            Blood pressure
                                        </b-col>
                                        <b-col>
                                            <b-badge variant="danger" pill>
                                                <feather-icon
                                                    icon="XIcon"
                                                ></feather-icon>
                                            </b-badge>
                                        </b-col>
                                        <b-col>
                                            <small>Mediciones alarmantes</small>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row class="pt-2">
                                <b-col>
                                    <h3 class="text-center pb-1">
                                        Observaciones
                                    </h3>
                                    <p class="text-justify">
                                        El paciente refiere constante cansancio,
                                        fátiga y sueño; se le recomienda dormir
                                        más y dejar de lado cualquier
                                        distractor.
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <h3 class="text-center pb-1">
                                        Recomendaciones
                                    </h3>
                                    <b-row>
                                        <b-col cols="8">
                                            <h4 class="text-center">
                                                Recomendación
                                            </h4>
                                        </b-col>
                                        <b-col>
                                            <h4 class="text-center">
                                                Periodicidad
                                            </h4>
                                        </b-col>
                                    </b-row>
                                    <b-row
                                        v-for="index in 5"
                                        :key="index"
                                        class="mb-1"
                                    >
                                        <b-col cols="auto">
                                            <feather-icon
                                                icon="CheckIcon"
                                            ></feather-icon>
                                        </b-col>
                                        <b-col cols="8">
                                            <div
                                                v-b-tooltip.hover.topright
                                                title="Lorem ipsum, dolor sit amet
                                                consectetur adipisicing elit.
                                                Consequuntur natus quae quas,
                                                quis maxime illum alias, dolore
                                                beatae distinctio delectus
                                                quisquam porro molestiae dolor,
                                                voluptas odio pariatur rem
                                                voluptatibus officiis."
                                            >
                                                <div
                                                    class="tres-puntos d-block w-40"
                                                >
                                                    Realizar ejercicio y dormir
                                                    mínimo 8 horas diarias
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="auto" class="text-right">
                                            <span> Cada {{ index }} día </span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <template v-slot:overlay>
                <div class="text-center p-3 mt-5">
                    <p>
                        <strong id="form-confirm-label"
                            >¿Deseas finalizar la asistencia?</strong
                        >
                    </p>
                    <div class="d-flex justify-content-center">
                        <b-button
                            variant="outline-danger"
                            class="mr-3"
                            @click="modalFinalizarAsistencia = false"
                        >
                            No
                        </b-button>
                        <b-button
                            variant="outline-success"
                            @click="finalizarAsistencia"
                        >
                            Sí
                        </b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    setup() {
        const modalFinalizarAsistencia = ref(false);
        const seguimientoEstado = ref(1);

        function finalizarAsistencia() {
            seguimientoEstado.value = 2;
            modalFinalizarAsistencia.value = false;
        }

        return {
            modalFinalizarAsistencia,
            seguimientoEstado,
            finalizarAsistencia,
        };
    },
};
</script>
<style lang="css" scope>
.gancho {
    display: block;
    position: relative;
    height: 12rem;
    width: 14rem;
    margin: auto;
    background-image: url("/img/clipboard/gancho.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;
    transform: rotate(0.4deg);
}
.informe {
    background-image: url("/img/clipboard/background.jpg");
    background-size: contain;
}
.papel {
    position: relative;
    background-color: white;
    top: -50px;
    z-index: 5;
}
</style>
